import { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Checkbox,
  Typography,
  Alert,
} from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { SimpleLayout } from '../src/layouts'
import { useRouter } from 'next/router'
import { AuthApi } from '../src/services/api/auth'
import { removeCookie, setCookie } from '../src/services/cookies'
import { removeAuthorizationHeader } from '../src/utils/token'
import { logEvent, setGAUser } from '../src/services/analytics'
import Link from 'next/link'
import { TermPageApi } from '../src/services/api/termPage'
import { TermPage } from '../src/services/types'
import { NextPageContext } from 'next'

const { Title } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const trackLogin = ({ user = '' }) => {
  if (!user) return

  setGAUser({ username: user })
  logEvent({ category: 'User', action: 'Logged In', label: user })
}

interface LoginProps {
  termsAndConditionsPage?: TermPage
  privacyPage?: TermPage
}

function Login({ termsAndConditionsPage, privacyPage }: LoginProps) {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  let checkboxLabel = null
  let errorMessage = null

  if (!!termsAndConditionsPage) {
    checkboxLabel = (
      <>
        I accept The FootprintCalculator{' '}
        <Link href='/term-and-conditions' passHref>
          <a target='_blank'>{termsAndConditionsPage.title}</a>
        </Link>
      </>
    )
    errorMessage = `Please accept the ${termsAndConditionsPage.title}.`
  }

  if (!!privacyPage) {
    checkboxLabel = (
      <>
        I accept The FootprintCalculator{' '}
        <Link href='/privacy-policy' passHref>
          <a target='_blank'>{privacyPage?.title}</a>
        </Link>
      </>
    )
    errorMessage = `Please accept the ${privacyPage.title}.`
  }

  if (!!termsAndConditionsPage && !!privacyPage) {
    checkboxLabel = (
      <>
        I accept The FootprintCalculator{' '}
        <Link href='/term-and-conditions' passHref>
          <a target='_blank'>{termsAndConditionsPage.title}</a>
        </Link>{' '}
        and{' '}
        <Link href='/privacy-policy' passHref>
          <a target='_blank'>{privacyPage.title}</a>
        </Link>
      </>
    )
    errorMessage = `Please accept the ${termsAndConditionsPage.title} & ${privacyPage.title}.`
  }

  const onFinish = async (values: any) => {
    removeCookie('csrftoken')
    removeCookie('sessionid')
    try {
      setLoading(true)
      setError(false)
      const { key } = await AuthApi.login(values.username, values.password)
      let now = new Date()
      now.setMonth(now.getMonth() + 1)
      setCookie('token', key, values.remember ? now : 1)
      trackLogin({ user: values.username })
      router.push('/welcome/')
    } catch (e) {
      console.log(e)
      setError(true)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    removeAuthorizationHeader()
  }, [])

  return (
    <SimpleLayout>
      <Row justify='center' gutter={32}>
        <Col span={24} md={12} lg={9} xl={6} xxl={5}>
          <Title style={{ textAlign: 'center' }}>Login</Title>
          <Card>
            <Form
              {...layout}
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              wrapperCol={{ span: 24 }}
            >
              <Form.Item
                name='username'
                rules={[
                  { required: true, message: 'Please input your Username!' },
                ]}
              >
                <Input
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder='Username'
                />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
              >
                <Input
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  type='password'
                  placeholder='Password'
                />
              </Form.Item>
              {error && (
                <Form.Item>
                  <Alert
                    message='Sorry, unable to log in with the username and password provided.'
                    type='error'
                    showIcon
                  />
                </Form.Item>
              )}
              {!!errorMessage && (
                <Form.Item
                  name='term_and_condition'
                  valuePropName='checked'
                  rules={[
                    {
                      required: true,
                      transform: (value) => value || undefined,
                      type: 'boolean',
                      message: errorMessage,
                    },
                  ]}
                >
                  <Checkbox>{checkboxLabel}</Checkbox>
                </Form.Item>
              )}
              <Row justify='space-between'>
                <Col>
                  <Form.Item>
                    <Form.Item name='remember' valuePropName='checked' noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button type='primary' htmlType='submit' loading={loading}>
                      Log in
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Row justify='center' style={{ marginTop: 10 }}>
            <Col>
              <Link href='/forgot-password' passHref>
                <a>Forgot password</a>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </SimpleLayout>
  )
}

Login.getInitialProps = async (ctx: NextPageContext) => {
  removeAuthorizationHeader(ctx)
  const data = await TermPageApi.getTermPage()
  const termsAndConditionsPage = data.find(
    (it: any) => it.page_type === 'terms'
  )
  const privacyPage = data.find((it: any) => it.page_type === 'privacy')

  return {
    privacyPage,
    termsAndConditionsPage,
  }
}

export default Login
